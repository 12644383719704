/* Buttons
   ========================================================================== */

// General
.btn {
    display: inline-block;
    margin-bottom: 20px;
    padding: 8px 20px;
    background-color: $primary;
    color: $white;
    border-width: 2px !important;
    border-style: solid !important;
    border-color: $primary;
    text-decoration: none !important;
    border-radius: 5px;
    &:visited {
        color: $white;
        border-color: $primary;
    }
    &:hover {
        background-color: $white;
        color: $primary;
        border-color: $primary;
    }
}

// Success button
.btn-success {
    background-color: $success;
    color: $white;
    border-color: $success;
    &:visited {
        color: $white;
        border-color: $success;
    }
    &:hover {
        background-color: $white;
        color: $success;
        border-color: $success;
    }
}

// Warning button
.btn-warning {
    background-color: $warning;
    color: $white;
    border-color: $warning;
    &:visited {
        color: $white;
        border-color: $warning;
    }
    &:hover {
        background-color: $white;
        color: $warning;
        border-color: $warning;
    }
}

// Danger button
.btn-danger {
    background-color: $danger;
    color: $white;
    border-color: $danger;
    &:visited {
        color: $white;
        border-color: $danger;
    }
    &:hover {
        background-color: $white;
        color: $danger;
        border-color: $danger;
    }
}

// Information button
.btn-info {
    background-color: $info;
    color: $white;
    border-color: $info;
    &:visited {
        border-color: $info;
        color: $white;
    }
    &:hover {
        background-color: $white;
        color: $info;
        border-color: $info;
    }
}

.twitter {
    background-color: #77bcf1;
    color: $white;
    border-color: #77bcf1;
    &:visited {
        color: $white;
        border-color: #77bcf1;
    }
    &:hover {
        background-color: $white;
        color: #77bcf1;
        border-color: #77bcf1;
    }
}

.facebook {
    background-color: #627aac;
    color: $white;
    border-color: #627aac;
    &:visited {
        color: $white;
        border-color: #627aac;
    }
    &:hover {
        background-color: $white;
        color: #627aac;
        border-color: #627aac;
    }
}

.google-plus {
    background-color: #e36f60;
    color: $white;
    border-color: #e36f60;
    &:visited {
        color: $white;
        border-color: #e36f60;
    }
    &:hover {
        background-color: $white;
        color: #e36f60;
        border-color: #e36f60;
    }
}

.hacker-news {
    background-color: #f60;
    color: $white;
    border-color: #f60;
    &:visited {
        color: $white;
        border-color: #f60;
    }
    &:hover {
        background-color: $white;
        color: #f60;
        border-color: #f60;
    }
}

.reddit {
    background-color: #5f99cf;
    color: $white;
    border-color: #5f99cf;
    &:visited {
        color: $white;
        border-color: #5f99cf;
    }
    &:hover {
        background-color: $white;
        color: #5f99cf;
        border-color: #5f99cf;
    }
}